import React from 'react';
import { Button } from '../button';
import { CREATR_DISCORD } from '@/constants/Links';
import { FaDiscord } from 'react-icons/fa';
import { Crown, Gem } from 'lucide-react';
import { useClerk, UserButton } from '@clerk/nextjs';
import { Skeleton } from '../skeleton';
import { useQueryState } from 'nuqs';
import { usePricingStore } from '@/store/usePricingStore';

type Props = {
  isPremiumUser: boolean;
  isSignedIn: boolean;
};

const NavUserSection = ({ isSignedIn, isPremiumUser }: Props) => {
  return (
    <div className="hidden items-center gap-2 md:flex">
      <Button
        variant={'outline'}
        onClick={() => {
          window.open(CREATR_DISCORD, '_blank');
        }}
        className="font-base rounded-xl border-[#EEEEEE] shadow-none"
      >
        <FaDiscord className="text-[#5865F2]" />
        Join Discord
      </Button>
      <PremiumButton
        isSignedIn={isSignedIn || false}
        isPremiumUser={isPremiumUser}
      />
      {isSignedIn && <div className="h-[16px] w-[1px] bg-border"></div>}

      {isSignedIn ? (
        <UserButton
          fallback={<Skeleton className="h-[28px] w-[28px] rounded-full" />}
        />
      ) : (
        <AuthButtons />
      )}
    </div>
  );
};

function PremiumButton({
  isSignedIn,
  isPremiumUser,
}: {
  isSignedIn: boolean;
  isPremiumUser: boolean;
}) {
  const showPricing = usePricingStore(state => state.showPricing);

  if (!isSignedIn) return null;

  if (isSignedIn && !isPremiumUser)
    return (
      <Button
        variant={'outline'}
        onClick={() =>
          showPricing('premium_feature', {
            limits: {
              remaining: 0,
              total: 0,
            },
            upgrade: {
              monthly: {
                amount: 89,
                interval: 'month',
              },
              annual: {
                amount: 59,
                interval: 'year',
                savings: '33%',
              },
            },
          })
        }
        className="font-base rounded-xl border-[#FF6321]/40 shadow-none"
      >
        <Gem className="text-[#FF6C37]" />
        Upgrade Plan
      </Button>
    );
  return (
    <Button
      variant={'outline'}
      className="font-base rounded-xl border-[#FF6321]/40 shadow-none"
    >
      <Crown className="text-[#FF6C37]" />
      Premium
    </Button>
  );
}

function AuthButtons() {
  const { openSignIn, openSignUp } = useClerk();

  const handleSignIn = () => {
    openSignIn({
      withSignUp: true,
      forceRedirectUrl: `/`,
      appearance: {
        layout: {
          socialButtonsPlacement: 'bottom',
          socialButtonsVariant: 'blockButton',
          unsafe_disableDevelopmentModeWarnings: true,
        },
        elements: {
          formButtonPrimary:
            'w-full rounded-lg bg-indigo-600 px-4 py-3 font-medium  shadow-lg shadow-indigo-500/25 transition-all duration-200 hover:scale-[1.02] hover:bg-indigo-500 disabled:opacity-50 disabled:hover:scale-100',
          card: '!shadow-none border-none ',
          afterButtonsNonLogin: 'hidden',
          footer: 'hidden',
          cardBox: '!shadow-none',
          modalContent: 'h-screen !items-center',
          modalBackdrop: '!items-center',
        },
      },
    });
  };
  const handleSignUp = () => {
    openSignUp({
      forceRedirectUrl: `/`,
      appearance: {
        layout: {
          socialButtonsPlacement: 'bottom',
          socialButtonsVariant: 'blockButton',
          unsafe_disableDevelopmentModeWarnings: true,
        },
        elements: {
          formButtonPrimary:
            'w-full rounded-lg bg-indigo-600 px-4 py-3 font-medium  shadow-lg shadow-indigo-500/25 transition-all duration-200 hover:scale-[1.02] hover:bg-indigo-500 disabled:opacity-50 disabled:hover:scale-100',
          card: '!shadow-none border-none ',
          afterButtonsNonLogin: 'hidden',
          footer: 'hidden',
          cardBox: '!shadow-none',
          modalContent: 'h-screen !items-center',
          modalBackdrop: '!items-center',
        },
      },
    });
  };
  return (
    <>
      <Button
        onClick={handleSignIn}
        variant={'outline'}
        className="rounded-xl shadow-none"
      >
        Log In
      </Button>
      <Button onClick={handleSignUp} className="rounded-xl shadow-none">
        Sign Up
      </Button>
    </>
  );
}

export default NavUserSection;
