import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface SubscriptionLimits {
  limits: {
    remaining: number;
    total: number;
  };
  upgrade: {
    monthly: {
      amount: number;
      interval: string;
    };
    annual: {
      amount: number;
      interval: string;
      savings: string;
    };
  };
}

type PricingSource =
  | 'project_creation'
  | 'edits_limit'
  | 'low_edits'
  | 'subscription_expired'
  | 'premium_feature'
  | null;

export interface PricingState {
  isOpen: boolean;
  source: PricingSource;
  limits: SubscriptionLimits | null;
  isLoading: boolean;
  lastUpgradeAttempt: {
    status: 'idle' | 'loading' | 'success' | 'error';
    error?: string;
    redirectUrl?: string;
  };
  setOpen: (isOpen: boolean) => void;
  showPricing: (source: PricingSource, limits?: SubscriptionLimits) => void;
  setLoading: (isLoading: boolean) => void;
  setUpgradeStatus: (
    status: 'idle' | 'loading' | 'success' | 'error',
    error?: string,
    redirectUrl?: string,
  ) => void;
  reset: () => void;
}

export const usePricingStore = create<PricingState>()(
  persist(
    set => ({
      isOpen: false,
      source: null,
      limits: null,
      isLoading: false,
      lastUpgradeAttempt: {
        status: 'idle',
      },
      setOpen: isOpen => set({ isOpen }),
      showPricing: (source, limits) =>
        set({
          isOpen: true,
          source,
          limits,
          // Reset upgrade attempt state when showing pricing
          lastUpgradeAttempt: { status: 'idle' },
        }),
      setLoading: isLoading => set({ isLoading }),
      setUpgradeStatus: (status, error, redirectUrl) =>
        set({
          lastUpgradeAttempt: { status, error, redirectUrl },
        }),
      reset: () =>
        set({
          isOpen: false,
          source: null,
          limits: null,
          isLoading: false,
          lastUpgradeAttempt: { status: 'idle' },
        }),
    }),
    {
      name: 'pricing-storage',
      // Only persist these fields
      partialize: state => ({
        limits: state.limits,
        lastUpgradeAttempt: state.lastUpgradeAttempt,
      }),
    },
  ),
);
