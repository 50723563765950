import React from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import { motion } from 'framer-motion';

const NavbarSkeleton = () => {
  return (
    <div className="relative z-50 mx-auto flex items-center justify-between rounded-xl border bg-white p-3 pl-5">
      <div className="flex items-center gap-6">
        {/* Logo */}
        <Skeleton className="h-7 w-20" />

        <div className="hidden h-[16px] w-[1px] bg-gray-200 md:block"></div>

        {/* Desktop Navigation */}
        <nav className="hidden items-center gap-5 md:flex">
          <Skeleton className="h-5 w-16" />
          <Skeleton className="h-5 w-28" />
          <Skeleton className="h-5 w-24" />
          <Skeleton className="h-5 w-16" />
          <Skeleton className="h-5 w-20" />
        </nav>
      </div>

      {/* Desktop Actions */}
      <div className="hidden items-center gap-2 md:flex">
        <Skeleton className="h-9 w-36 rounded-xl" />
        <Skeleton className="h-9 w-32 rounded-xl" />
        <div className="h-[16px] w-[1px] bg-gray-200"></div>
        <Skeleton className="h-[28px] w-[28px] rounded-full" />
      </div>

      {/* Mobile Actions */}
      <div className="flex items-center gap-2 md:hidden">
        <Skeleton className="h-9 w-9 rounded-full" />
        <Skeleton className="h-8 w-8 rounded-md" />
      </div>
    </div>
  );
};

export default NavbarSkeleton;
