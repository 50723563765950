import api from '@/lib/axios';
import { useAuth } from '@clerk/nextjs';
import { useState, useEffect } from 'react';

interface SubscriptionDetails {
  status: string;
  // Add other subscription fields you need
}

export const useSubscription = (shouldFetch = true) => {
  const [details, setDetails] = useState<SubscriptionDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { getToken, isSignedIn } = useAuth();

  const fetchSubscriptionDetails = async () => {
    if (!isSignedIn) {
      setDetails(null);
      return;
    }

    setError(null);
    setIsLoading(true);

    try {
      const token = await getToken();
      const response = await api.get('/api/stripe/subscription-details', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDetails(response.data.data);
    } catch (error) {
      setError(
        error instanceof Error ? error : new Error('Unknown error occurred'),
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (shouldFetch) {
      fetchSubscriptionDetails();
    }
  }, [isSignedIn, shouldFetch]);

  const isPremiumUser = details?.status === 'premium' || false;

  return {
    details,
    isLoading,
    error,
    isPremiumUser,
    refetch: fetchSubscriptionDetails,
  };
};
