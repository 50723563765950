import { AnimatePresence, motion } from 'framer-motion';
import { Menu } from 'lucide-react';
import { useState } from 'react';
import { UserButton, useUser } from '@clerk/nextjs';
import ChangeLog from '../ChangeLog';
import { PricingPopup } from './pricing-popup';
import NavbarSkeleton from './navbar-skeleton';
import { useSubscription } from '@/hooks/useSubscription';
import MobileMenu from './nav/mobile-menu';
import NavLinks from './nav/links';
import NavUserSection from './nav/user-section';
import { usePricingStore } from '@/store/usePricingStore';

export function Navbar() {
  const [showChangeLogs, setShowChangeLogs] = useState(false);
  const { setOpen } = usePricingStore();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { isSignedIn, isLoaded } = useUser();
  const { isPremiumUser, isLoading: isSubscriptionLoading } = useSubscription();

  if (!isLoaded || isSubscriptionLoading) return <NavbarSkeleton />;
  return (
    <>
      <ChangeLog
        isOpen={showChangeLogs}
        onClose={() => setShowChangeLogs(false)}
        version="0.1.0"
      />
      <div className="relative z-50 mx-auto flex items-center justify-between rounded-xl border bg-white p-3 pl-5">
        {/* Desktop Actions */}
        <NavLinks
          setShowPricingPopup={setOpen}
          setShowChangeLogs={setShowChangeLogs}
          isSubscriptionLoading={isSubscriptionLoading}
          isPremiumUser={isPremiumUser}
        />
        <NavUserSection isSignedIn={isSignedIn} isPremiumUser={isPremiumUser} />

        {/* Mobile Actions - Moved hamburger here */}
        <div className="flex items-center gap-2 md:hidden">
          {isSignedIn && <UserButton />}
          <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="p-2">
            <Menu size={24} />
          </button>
        </div>

        {/* Mobile Menu */}
        <MobileMenu
          isMenuOpen={isMenuOpen}
          isSignedIn={isSignedIn}
          setIsMenuOpen={setIsMenuOpen}
          setShowPricingPopup={setOpen}
          setShowChangeLogs={setShowChangeLogs}
        />
      </div>
    </>
  );
}
