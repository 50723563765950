import Link from 'next/link';
import React from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu';
import { toggleChatwoot } from '@/components/ChatwootWidget';
import { MessageCircle, Phone } from 'lucide-react';
import NavLeftSkeleton from '../nav-left-skeleton';

type Props = {
  isSubscriptionLoading: boolean;
  isPremiumUser: boolean;
  setShowPricingPopup: (a: boolean) => void;
  setShowChangeLogs: (a: boolean) => void;
};

const NavLinks = ({
  isSubscriptionLoading,
  isPremiumUser,
  setShowPricingPopup,
  setShowChangeLogs,
}: Props) => {
  return (
    <div className="flex items-center gap-6">
      <Link href="/" className="text-lg font-semibold text-black">
        Creatr
      </Link>
      <div className="hidden h-[16px] w-[1px] bg-border md:block"></div>

      {/* Desktop Navigation */}
      {!isSubscriptionLoading ? (
        <nav className="hidden items-center gap-5 md:flex">
          {!isPremiumUser && (
            <div
              onClick={() => setShowPricingPopup(true)}
              className="cursor-pointer text-sm transition-colors"
            >
              Pricing
            </div>
          )}

          <Link
            href="https://doc.getcreatr.com"
            target="_blank"
            className="text-sm transition-colors"
          >
            Documentation
          </Link>

          <div
            onClick={() => setShowChangeLogs(true)}
            className="cursor-pointer text-sm transition-colors"
          >
            Change Logs
          </div>
          <div className="cursor-pointer text-sm transition-colors">
            <a
              href="https://www.youtube.com/watch?v=4VUBzeUuksM"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tutorial
            </a>
          </div>

          <div className="">
            <DropdownMenu>
              <DropdownMenuTrigger className="flex items-center text-sm">
                Support
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-1"
                >
                  <path
                    d="M6 9L12 15L18 9"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                side="bottom"
                align="start"
                className="w-64 rounded-xl p-1 shadow-md"
              >
                <DropdownMenuItem
                  onClick={toggleChatwoot}
                  className="flex cursor-pointer items-center gap-3 rounded-lg p-2 hover:bg-gray-100"
                >
                  <div className="relative rounded-full p-1">
                    <MessageCircle className="text" size={16} />
                  </div>
                  <div className="flex w-full items-center">
                    <span className="font text-sm">Chat Support</span>
                    <span className="ml-2 text-sm text-gray-400">5 mins</span>
                  </div>
                </DropdownMenuItem>

                <DropdownMenuItem
                  onClick={() => {
                    window.open(
                      'https://cal.com/kartik-sharma-7ikcki/shape-the-future-of-creatr',
                      '_blank',
                    );
                  }}
                  className="mt-1 flex cursor-pointer items-center gap-3 rounded-lg p-2 hover:bg-gray-100"
                >
                  <div className="p-1">
                    <Phone className="text" size={16} />
                  </div>
                  <div className="flex w-full items-center">
                    <span className="text-sm">Call With Founder</span>
                    <span className="ml-2 text-sm text-gray-400">Few days</span>
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </nav>
      ) : (
        <NavLeftSkeleton />
      )}
    </div>
  );
};

export default NavLinks;
