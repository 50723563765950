import { Skeleton } from './skeleton';

function NavLeftSkeleton() {
  return (
    <nav className="hidden items-center gap-5 md:flex">
      <Skeleton className="h-4 w-16 rounded-md" />
      <Skeleton className="h-4 w-28 rounded-md" />
      <Skeleton className="h-4 w-24 rounded-md" />
      <Skeleton className="h-4 w-16 rounded-md" />
      <Skeleton className="h-4 w-20 rounded-md" />
    </nav>
  );
}

export default NavLeftSkeleton;
