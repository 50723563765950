import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import Link from 'next/link';
import { Button } from '../button';
import { useClerk, UserButton } from '@clerk/nextjs';
import { FaDiscord } from 'react-icons/fa';
import { toggleChatwoot } from '@/components/ChatwootWidget';
import { CREATR_DISCORD } from '@/constants/Links';

type Props = {
  isMenuOpen: boolean;
  isSignedIn: boolean;
  setIsMenuOpen: (value: boolean) => void;
  setShowPricingPopup: (value: boolean) => void;
  setShowChangeLogs: (value: boolean) => void;
};

const MobileMenu = ({
  isMenuOpen,
  isSignedIn,
  setIsMenuOpen,
  setShowPricingPopup,
  setShowChangeLogs,
}: Props) => {
  const { openSignIn } = useClerk();
  return (
    <AnimatePresence>
      {isMenuOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="absolute left-0 right-0 top-full mt-2 rounded-xl border bg-white p-4 shadow-lg md:hidden"
        >
          <div className="flex flex-col gap-4">
            {!isSignedIn && (
              <div
                onClick={() => {
                  setShowPricingPopup(true);
                  setIsMenuOpen(false);
                }}
                className="cursor-pointer text-sm transition-colors"
              >
                Pricing
              </div>
            )}

            <Link
              href="https://doc.getcreatr.com"
              target="_blank"
              className="text-sm transition-colors"
              onClick={() => setIsMenuOpen(false)}
            >
              Documentation
            </Link>

            <div
              onClick={() => setShowChangeLogs(true)}
              className="cursor-pointer text-sm transition-colors"
            >
              Change Logs
            </div>

            <a
              href="https://www.youtube.com/watch?v=4VUBzeUuksM"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer text-sm transition-colors"
              onClick={() => setIsMenuOpen(false)}
            >
              Tutorial
            </a>

            <div
              onClick={() => {
                toggleChatwoot();
                setIsMenuOpen(false);
              }}
              className="cursor-pointer text-sm transition-colors"
            >
              Support
            </div>

            <div className="flex flex-col gap-2 border-t pt-4">
              <Button
                variant={'outline'}
                onClick={() => {
                  window.open(CREATR_DISCORD, '_blank');
                  setIsMenuOpen(false);
                }}
                className="font-base w-full rounded-xl border-[#EEEEEE] shadow-none"
              >
                <FaDiscord className="text-[#5865F2]" />
                Join Discord
              </Button>

              {isSignedIn ? (
                <div className="flex justify-center">
                  <UserButton />
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  <Button
                    onClick={() => {
                      openSignIn({
                        withSignUp: true,
                        forceRedirectUrl: `/`,
                        appearance: {
                          layout: {
                            socialButtonsPlacement: 'bottom',
                            socialButtonsVariant: 'blockButton',
                            unsafe_disableDevelopmentModeWarnings: true,
                          },
                          elements: {
                            formButtonPrimary:
                              'w-full rounded-lg bg-indigo-600 px-4 py-3 font-medium  shadow-lg shadow-indigo-500/25 transition-all duration-200 hover:scale-[1.02] hover:bg-indigo-500 disabled:opacity-50 disabled:hover:scale-100',
                            card: '!shadow-none border-none ',
                            afterButtonsNonLogin: 'hidden',
                            footer: 'hidden',
                            cardBox: '!shadow-none',
                            modalContent: 'h-screen !items-center',
                            modalBackdrop: '!items-center',
                          },
                        },
                      });
                      setIsMenuOpen(false);
                    }}
                    variant={'outline'}
                    className="w-full rounded-xl shadow-none"
                  >
                    Log In
                  </Button>
                  <Button
                    onClick={() => {
                      openSignIn({
                        withSignUp: true,
                        forceRedirectUrl: `/`,
                        appearance: {
                          layout: {
                            socialButtonsPlacement: 'bottom',
                            socialButtonsVariant: 'blockButton',
                            unsafe_disableDevelopmentModeWarnings: true,
                          },
                          elements: {
                            formButtonPrimary:
                              'w-full rounded-lg bg-indigo-600 px-4 py-3 font-medium  shadow-lg shadow-indigo-500/25 transition-all duration-200 hover:scale-[1.02] hover:bg-indigo-500 disabled:opacity-50 disabled:hover:scale-100',
                            card: '!shadow-none border-none ',
                            afterButtonsNonLogin: 'hidden',
                            footer: 'hidden',
                            cardBox: '!shadow-none',
                            modalContent: 'h-screen !items-center',
                            modalBackdrop: '!items-center',
                          },
                        },
                      });
                      setIsMenuOpen(false);
                    }}
                    className="w-full rounded-xl shadow-none"
                  >
                    Sign Up
                  </Button>
                </div>
              )}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MobileMenu;
