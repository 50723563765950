import { X, Sparkles, Zap, Bug } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface MediaItem {
  type: 'image' | 'video' | 'youtube';
  url: string;
  alt?: string;
}

interface Change {
  type: 'feature' | 'improvement' | 'fix';
  title: string;
  description: string;
  items: string[];
  media?: MediaItem[];
}

interface ChangeLogProps {
  isOpen: boolean;
  onClose: () => void;
  version: string;
  title?: string;
  description?: string;
  changes?: Change[];
}

const sampleChanges: Change[] = [
  {
    type: 'feature',
    title: 'New Supabase Integration is now Live',
    description:
      'Get database, storage, and authentication in just a couple of clicks with our new Supabase integration.',
    items: [
      'Seamless database management with Supabase',
      'Secure authentication with Supabase Auth',
      'Integrated file storage for your application',
    ],
    media: [
      {
        type: 'image',
        url: 'https://creatr-assets.s3.ap-south-1.amazonaws.com/Screenshot+2025-02-07+at+9.07.16%E2%80%AFPM.jpg',
        alt: 'Supabase Integrate Button',
      },
      {
        type: 'image',
        url: 'https://creatr-assets.s3.ap-south-1.amazonaws.com/Screenshot%202025-02-07%20at%209.14.09%E2%80%AFPM.png',
        alt: 'Dark mode interface preview',
      },
      {
        type: 'image',
        url: 'https://creatr-assets.s3.ap-south-1.amazonaws.com/Screenshot+2025-02-07+at+9.09.24%E2%80%AFPM.png',
        alt: 'Supabase integration preview',
      },
    ],
  },
  {
    type: 'feature',
    title: 'Easily Integrate Stripe Products',
    description:
      'You can now add Stripe product links directly without any hassle, making it easier than ever to sell your products.',
    items: [
      'Quickly embed Stripe product links',
      'Seamless checkout experience for your customers',
      'No manual setup required—just paste and go',
    ],
    media: [
      {
        type: 'youtube',
        url: 'https://www.youtube.com/embed/Em84wTYGD4c?si=HSd-EuNwf2J_JALj',
        alt: 'YouTube Demo Video',
      },
      {
        type: 'image',
        url: 'https://creatr-assets.s3.ap-south-1.amazonaws.com/Screenshot%202025-02-07%20at%209.14.09%E2%80%AFPM.png',
        alt: 'Stripe Checkout Preview',
      },
    ],
  },
];

import { cn } from '@/lib/utils';
import { useAuth } from '@clerk/nextjs';

export default function ChangeLog({
  isOpen,
  onClose,
  version = '2.4.0',
  title = "What's New",
  description = "Discover the latest improvements and new features we've added to enhance your experience",
  changes = sampleChanges,
}: ChangeLogProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-[60] flex items-center justify-center bg-black/10 p-4 backdrop-blur-sm"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            onClick={e => e.stopPropagation()}
            className="relative w-full max-w-3xl overflow-hidden rounded-2xl border border-zinc-200 bg-gradient-to-b from-white to-zinc-50/80 p-4 shadow-xl"
          >
            <button
              onClick={onClose}
              className="absolute right-4 top-4 rounded-full p-2 text-zinc-600 transition-colors hover:bg-zinc-100"
              aria-label="Close changelog"
            >
              <X size={20} />
            </button>

            <div className="space-y-2">
              <div className="p-2">
                <div className="inline-flex items-center rounded-full bg-zinc-100 px-3 py-1 text-xs text-zinc-600 ring-1 ring-inset ring-zinc-200">
                  <span className="mr-2">Version {version}</span>
                  <span className="inline-flex h-1.5 w-1.5 rounded-full bg-emerald-500"></span>
                </div>
                <h2 className="mt-4 bg-gradient-to-r from-zinc-900 to-zinc-600 bg-clip-text text-2xl font-bold tracking-tight text-transparent">
                  {title}
                </h2>
                <p className="mt-1 text-sm text-zinc-600">{description}</p>
              </div>

              <div className="max-h-[500px] space-y-4 overflow-y-auto pr-4">
                {changes.map((change, index) => (
                  <div
                    key={index}
                    className="rounded-xl bg-white p-6 ring-1 ring-inset ring-zinc-200"
                  >
                    <div className="flex items-center gap-x-3">
                      <div
                        className={cn('rounded-lg p-2 ring-1 ring-inset', {
                          'bg-emerald-50 ring-emerald-100':
                            change.type === 'feature',
                          'bg-amber-50 ring-amber-100':
                            change.type === 'improvement',
                          'bg-rose-50 ring-rose-100': change.type === 'fix',
                        })}
                      >
                        {change.type === 'feature' && (
                          <Sparkles className="h-5 w-5 text-emerald-400" />
                        )}
                        {change.type === 'improvement' && (
                          <Zap className="h-5 w-5 text-amber-500" />
                        )}
                        {change.type === 'fix' && (
                          <Bug className="h-5 w-5 text-rose-500" />
                        )}
                      </div>
                      <h3 className="font-semibold text-zinc-900">
                        {change.title}
                      </h3>
                    </div>
                    {change.media && change.media.length > 0 && (
                      <div className="mb-4 mt-4">
                        <div
                          className="flex items-center overflow-hidden rounded-lg border bg-gray-100 shadow"
                          style={{ position: 'relative', aspectRatio: '16/9' }}
                        >
                          <div className="flex transition-transform duration-300 ease-in-out">
                            {change.media.map((item, mediaIndex) =>
                              item.type === 'image' ? (
                                <img
                                  key={mediaIndex}
                                  src={item.url}
                                  alt={item.alt || `Media ${mediaIndex + 1}`}
                                  className="h-full w-full flex-shrink-0 object-cover"
                                />
                              ) : item.type === 'video' ? (
                                <video
                                  key={mediaIndex}
                                  autoPlay
                                  loop
                                  muted
                                  playsInline
                                  className="h-full w-full flex-shrink-0 object-cover"
                                >
                                  <source src={item.url} type="video/mp4" />
                                </video>
                              ) : item.type === 'youtube' ? (
                                <div className="video-container h-full w-full flex-shrink-0">
                                  <iframe
                                    key={mediaIndex}
                                    src={item.url}
                                    title={
                                      item.alt ||
                                      `YouTube video ${mediaIndex + 1}`
                                    }
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    className="!h-full w-full flex-shrink-0"
                                  ></iframe>
                                </div>
                              ) : null,
                            )}
                          </div>
                          {change.media.length > 1 && (
                            <>
                              <button
                                onClick={() => {
                                  const container = document.querySelector(
                                    '.transition-transform',
                                  ) as HTMLElement;
                                  if (container) {
                                    const currentTransform =
                                      container.style.transform ||
                                      'translateX(0%)';
                                    const currentX = parseInt(
                                      currentTransform.replace(/[^0-9-]/g, ''),
                                    );
                                    if (
                                      currentX >
                                      -(change.media!.length - 1) * 100
                                    ) {
                                      container.style.transform = `translateX(${currentX - 100}%)`;
                                    }
                                  }
                                }}
                                className="absolute right-4 top-1/2 -translate-y-1/2 rounded-full bg-white/80 p-2 text-zinc-700 shadow-lg backdrop-blur-sm transition-all hover:bg-white hover:text-zinc-900"
                                aria-label="Next image"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="m9 18 6-6-6-6" />
                                </svg>
                              </button>
                              <button
                                onClick={() => {
                                  const container = document.querySelector(
                                    '.transition-transform',
                                  ) as HTMLElement;
                                  if (container) {
                                    const currentTransform =
                                      container.style.transform ||
                                      'translateX(0%)';
                                    const currentX = parseInt(
                                      currentTransform.replace(/[^0-9-]/g, ''),
                                    );
                                    if (currentX < 0) {
                                      container.style.transform = `translateX(${currentX + 100}%)`;
                                    }
                                  }
                                }}
                                className="absolute left-4 top-1/2 -translate-y-1/2 rounded-full bg-white/80 p-2 text-zinc-700 shadow-lg backdrop-blur-sm transition-all hover:bg-white hover:text-zinc-900"
                                aria-label="Previous image"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <path d="m15 18-6-6 6-6" />
                                </svg>
                              </button>
                              <div className="absolute bottom-4 left-1/2 flex -translate-x-1/2 gap-2">
                                {change.media.map((_, mediaIndex) => (
                                  <button
                                    key={mediaIndex}
                                    onClick={() => {
                                      const container = document.querySelector(
                                        '.transition-transform',
                                      ) as HTMLElement;
                                      if (container) {
                                        container.style.transform = `translateX(-${mediaIndex * 100}%)`;
                                      }
                                    }}
                                    className="h-2 w-2 rounded-full bg-white/50 transition-all hover:bg-white"
                                    aria-label={`Go to slide ${mediaIndex + 1}`}
                                  />
                                ))}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <p className="mb-4 mt-2 text-zinc-900">
                      {change.description}
                    </p>
                    <ul className="grid gap-3 text-zinc-600">
                      {change.items.map((item, itemIndex) => (
                        <li
                          key={itemIndex}
                          className="flex items-center gap-x-3"
                        >
                          <span
                            className={cn('h-1.5 w-1.5 rounded-full', {
                              'bg-emerald-400': change.type === 'feature',
                              'bg-amber-400': change.type === 'improvement',
                              'bg-rose-400': change.type === 'fix',
                            })}
                          ></span>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>

              <button
                onClick={onClose}
                className="mt-6 w-full rounded-lg bg-gradient-to-r from-emerald-500 to-emerald-600 px-4 py-2.5 text-sm font-medium text-white shadow-lg transition-all hover:from-emerald-600 hover:to-emerald-700 focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-white"
              >
                Continue to Dashboard
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
